import { Collapse, Image } from "antd";
import React from "react";

const LeftSideProfile = ({ taskData, affiliateAmount }) => {
  const text = `
    A dog is a type of domesticated animal.
    Known for its loyalty and faithfulness,
    it can be found as a welcome guest in many households across the world.
  `;
  return (
    <>
      <div className="bg-white rounded-lg h-full">
        <div className="relative flex justify-center">
          <div
            className="relative  flex justify-center"
            style={{
              // height: '260px',
              // width: '820px',
              // position: 'relative',
              // overflow: 'hidden',
              aspectRatio: "2.63:1",
            }}
          >
            <Image
              src={taskData?.featuredImage}
              alt={"Task banner"}
              className="rounded-t-xl object-cover"
              fill
              // width={680}
              // height={260}
              preview={false}
              // wrapperStyle={{ width: '100%', }}
            />
          </div>
        </div>
        {/* <div className="w-full aspect-[2.63/1] relative">
          <Image
            src={taskData?.featuredImage ?? "https://via.placeholder.com/500x300.png"}
            alt={"Task banner"}
            className="rounded-t-xl absolute w-full h-full object-cover"
            width={680}
            height={260}
            preview={false}
          />
        </div> */}
        <div className="relative px-3 sm:px-10 pb-5">
          <div className="relative">
            <div className="relative flex justify-between items-center">
              <Image
                src={taskData?.brand?.logo}
                alt={taskData?.brand?.name ?? "Brand logo"}
                className="w-full object-cover rounded-full border-4 border-white border-solid top-[-40px] left-[0px] absolute"
                preview={false}
                width={80}
                height={80}
              />
              <div className=" flex h-6 rounded-xl items-center">
                {affiliateAmount && (
                  <p className="text-[#28A745] font-semibold text-lg pe-3">
                    ₹ {affiliateAmount || 0}
                  </p>
                )}
                <p className="p-1 bg-[#DADDDF80] text-[#7D8995] rounded-s">
                  {taskData?.brand?.name ?? ""}
                </p>
                <p className="p-1 bg-[#DADDDF4D] text-[#7D8995] rounded-e">
                  App Install
                </p>
              </div>
            </div>
            <p className="text-2xl font-semibold">{taskData?.name ?? ""}</p>
            <div className="py-3">
              <Collapse
                style={{ border: "1px solid #D7D7D7", background: "white" }}
                // size="large"
                defaultActiveKey={["1"]}
                expandIconPosition="right"
                items={[
                  {
                    key: "1",
                    label: "Description",
                    children: (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: taskData?.description ?? "",
                        }}
                      />
                    ),
                  },
                ]}
              />
            </div>
            <div className="py-3">
              <Collapse
                style={{ border: "1px solid #D7D7D7", background: "white" }}
                // size="large"
                defaultActiveKey={["1"]}
                expandIconPosition="right"
                items={[
                  {
                    key: "1",
                    label: "Important Info",
                    children: (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: taskData?.termAndCondition ?? "",
                        }}
                      />
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeftSideProfile;
