import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  notification,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import FormFields from "../../../../component/common/FormFields";
import { UploadOutlined, InboxOutlined } from "@ant-design/icons";
import TaskSubmissionModal from "./task-submission-modal";
import WithdrawModal from "./withdraw-modal";
import Label from "../../../../component/common/Label";
import { AiOutlineInfoCircle } from "react-icons/ai";

const TaskApply = ({
  admindetails,
  api,
  setRefresh,
  show = false,
  status,
  setStatus,
  taskData,
  applyUserTaskHandler,
  isModalVisible,
  affiliateAmount,
}) => {
  const [form] = Form.useForm();
  const [checked, setChecked] = useState(false);
  const [isButton, setIsButton] = useState(false);

  const isButtonHandler = () => {
    setIsButton(false);
  };

  const [isSucess, setIsSucess] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [upiId, setUpiId] = useState(null);

  // const { userTaskId, userId } = useParams();
  // const api = useHttp();
  const formattedPathapply = taskData?.availableFileType
    ? "." + taskData?.availableFileType?.split(",")?.join(",.")
    : "all";
  useEffect(() => {
    if (!isSucess) return;
    const timer = setTimeout(() => {
      setRefresh((pr) => !pr);
      isModalVisible();
      form?.resetFields();
      setIsSucess((pr) => !pr);
    }, 3000);
    setTimeoutId(timer);
    return () => clearTimeout(timer);
  }, [isSucess]);

  const validateUpi = (_, value) => {
    const upiRegex = /^[\w.-]+@[\w.-]+$/;
    if (!value || upiRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("Enter a valid UPI ID (e.g., name@bank)");
  };

  return (
    <>
      <Modal
        width={440}
        open={show}
        onCancel={() => {
          form?.resetFields();
          setChecked(false);
          isModalVisible();
          isButtonHandler();
          if (timeoutId) {
            clearTimeout(timeoutId); // Clear the timeout when modal is closed
            setTimeoutId(null);
            setIsSucess(false);
            setRefresh((pr) => !pr);
          }
        }}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
      >
        {/* APPLY FORM */}
        {status === "Apply" ? (
          <div>
            <p className="py-2 font-semibold">Terms & Condition</p>
            <div
              className="py-2"
              dangerouslySetInnerHTML={{ __html: taskData?.termAndCondition }}
            />
            <div className="py-4">
              <Checkbox
                checked={checked}
                onChange={(e) => {
                  e.preventDefault();
                  setChecked(e?.target?.checked);
                  setIsButton((pr) => !pr);
                }}
              >
                I have read terms & conditions. and i'm accepted.
              </Checkbox>
            </div>
            {affiliateAmount && (
              <div className="py-4">
                <Label required>Your UPI ID</Label>
                <Form layout="vertical">
                  <Form.Item
                    className="w-full"
                    name="upiId"
                    rules={[
                      { required: true, message: "UPI ID is required" },
                      { validator: validateUpi },
                    ]}
                  >
                    <Input
                      placeholder="Enter UPI ID"
                      value={upiId}
                      onChange={(e) => setUpiId(e.target.value)}
                    />
                  </Form.Item>
                </Form>
                <div className="text-lg text-gray-500 cursor-pointer mt-1 flex items-center">
                  <AiOutlineInfoCircle className="me-1" />
                  <span className="text-sm">
                    The amount will be credited to this UPI ID.
                  </span>
                </div>
              </div>
            )}
            <Button
              loading={api?.isLoading}
              className={`mt-2 w-full`}
              type="primary"
              style={{
                background: !isButton ? "#DBECFF80" : "#007BFF",
                color: !isButton ? "#007BFF" : "#ffffff",
              }}
              onClick={() => {
                const upiRegex = /^[\w.-]+@[\w.-]+$/;
                if (
                  affiliateAmount &&
                  (!upiId?.trim() || !upiRegex.test(upiId))
                )
                  return;
                if (!checked)
                  return notification.error({
                    message: "Please accept terms & conditions",
                  });
                applyUserTaskHandler(
                  isModalVisible,
                  isButtonHandler,
                  upiId?.trim()
                );
              }}
            >
              Apply
            </Button>
          </div>
        ) : status === "InProgress" ||
          status === "Reject" ||
          status === "Rework" ? (
          <TaskSubmissionModal
            form={form}
            taskData={taskData}
            admindetails={admindetails}
            setRefresh={setRefresh}
            isModalVisible={isModalVisible}
            isButton={isButton}
            setIsButton={setIsButton}
            isSucess={isSucess}
            setIsSucess={setIsSucess}
            formattedPathapply={formattedPathapply}
            setStatus={setStatus}
          />
        ) : status === "Pending" ? (
          <WithdrawModal
            taskData={taskData}
            isModalVisible={isModalVisible}
            setRefresh={setRefresh}
          />
        ) : (
          <></>
        )}
      </Modal>
    </>
  );
};

export default TaskApply;
